:root {
    --background: #f5f3f2;
    --primary: #f34b15;
    --gray-main: #010c26;
    --gray-light: #676d7d;
}

@font-face {
    font-family: proxima-nova;
    font-style: normal;
    font-weight: 400;
    src: local(''),
            url('assets/fonts/proxima-nova-400.woff2') format('woff2')
}

@font-face {
    font-family: proxima-nova;
    font-style: italic;
    font-weight: 400;
    src: local(''),
            url('assets/fonts/proxima-nova-400-italic.woff2') format('woff2')
}

@font-face {
    font-family: proxima-nova;
    font-style: normal;
    font-weight: 500;
    src: local(''),
            url('./assets/fonts/proxima-nova-500.woff2') format('woff2')
}

h1 {
    margin-top: 3rem;
    margin-bottom: 2rem;
}

@font-face {
    font-family: proxima-nova;
    font-style: italic;
    font-weight: 500;
    src: local(''),
            url('assets/fonts/proxima-nova-500-italic.woff2') format('woff2')
}

@font-face {
    font-family: proxima-nova;
    font-style: normal;
    font-weight: 600;
    src: local(''),
            url('assets/fonts/proxima-nova-600.woff2') format('woff2')
}

@font-face {
    font-family: proxima-nova;
    font-style: italic;
    font-weight: 600;
    src: local(''),
            url('assets/fonts/proxima-nova-600-italic.woff2') format('woff2')
}

@font-face {
    font-family: proxima-nova;
    font-style: normal;
    font-weight: 700;
    src: local(''),
            url('assets/fonts/proxima-nova-700.woff2') format('woff2')
}

@font-face {
    font-family: proxima-nova;
    font-style: italic;
    font-weight: 700;
    src: local(''),
            url('assets/fonts/proxima-nova-700-italic.woff2') format('woff2')
}

@font-face {
    font-family: 'kenteken';
    font-style: normal;
    font-weight: 400;
    src: local('kenteken'),
    url('assets/fonts/kenteken.woff') format('woff');
}

* {
    font-family: proxima-nova !important;
}

body {
    background-color: var( --background );
    color: var( --gray-main );
    line-height: 1.5rem;
}

a {
    color: var( --gray-main );
    text-underline-offset: 2px;
}

/* Header navigation */
nav a {
    display: inline-block;
    position: relative;
    text-decoration: none;
    padding: 4px 4px 1px;
    margin-right: 20px;
    margin-left: 20px;
    color: rgb(1, 12, 38);
    text-transform: none;
    font-weight: bold;
}

nav a.active:after,
nav a:hover:after {
    background: linear-gradient(to left, #f34b15, #f75c0e 32%, #ff8000);
    bottom: -10px;
    content: "";
    display: block;
    height: 3px;
    left: 50%;
    position: absolute;
    -webkit-transform: translate(-50%,0);
    -moz-transform: translate(-50%,0);
    -ms-transform: translate(-50%,0);
    transform: translate(-50%,0);
    width: 44px;
}

nav a.user {
    font-size: 1rem;
    font-weight: 500;
}


/* Table overview */
.MuiDataGrid-iconSeparator {
    display: none !important;
}

/* Filter */
input, div.MuiSelect-select {
    height: 0.5rem !important;
    padding: 0.625rem 2rem 0.5rem 1rem;
    width: 100% !important;
}

.MuiFormControl-root {
    width: 100%;
}

label[data-shrink=false] {
    font-size: small;
}

.MuiFormLabel-root.Mui-focused {
    color: black !important
}

.MuiGrid-root .MuiInputBase-formControl {
    margin-bottom: 1rem !important;
}

@media (min-width: 1024px) {
    .MuiGrid-root .MuiInputBase-formControl {
        margin: 0 1.5rem 0.5rem 0 !important;
    }
}

.MuiGrid-root .MuiTextField-root {
    width: 100%;
}

.MuiGrid-root .date-picker > div .MuiFormControl-root:first-child .MuiInputBase-formControl {
    margin-right: 0.825rem !important;
}

.MuiGrid-root .date-picker > div {
    display: flex;
    flex-direction: row;
}

/* Content Pages */
.content a {
    color: var( --primary );
}

.content a:focus,
.content a:hover {
    color: var( --gray-main );
}

.content table th,
.content table td {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.content table th:first-of-type,
.content table td:first-of-type {
    padding-left: 0;
}

.content table th:last-of-type,
.content table td:last-of-type {
    padding-right: 0;
}

/* Footer */
.footer a:focus,
.footer a:hover {
    color: var( --gray-light );
}

/* Disable heavy ripple effect with list/card view */
.MuiTouchRipple-rippleVisible {
  display: none;
}

/* Cookie Statement */
.cookie-statement-table {
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 2rem;
}

.cookie-statement-table tr {
    border-bottom: 1px solid var( --gray-light );
}

.cookie-statement-table tr:last-of-type {
    border-bottom: 0;
}

.cookie-statement-table td {
    padding: 1rem;
}
